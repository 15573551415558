@import "../constants";
@import "../mixins/svgiconMixins";

.dropdownMenu {
    margin: 0 16px;
    position: relative;

    &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        background-color: var(--sf-modal-bg-color);
        width: 100%;
        content: '';
    }
}

.dropdownMenu__item {
    line-height: 24px;
    display: flex;
    font-weight: var(--sf-font-weight-semibold);
    width: 100%;
    color: var(--sf-text-color);
    position: relative;
    border: none;
    background: none transparent;
    justify-content: flex-start;
    align-items: center;
    min-height: 24px;
    padding: 12px 0;
    border-bottom: 1px solid var(--sf-sidebar-nav-separator-color);

    &.--link {
        cursor: pointer;
        @media (hover) {
            &:hover {
                .dropdownMenu__name {
                    color: var(--sf-color-primary);
                    text-decoration: underline;
                    text-underline-offset: .5ex;
                }
            }
        }
    }

    &.--note {
        user-select: text;
    }

    &.--hiddenDesktop {
        @media (min-width: $screen-1200px) {
            display: none;
        }
    }
}

.dropdownMenu__icon {
    flex-shrink: 0;
    margin-inline-end: 1em;
    width: 24px;
    display: flex;

    picture {
        display: contents;

        img {
            height: 24px;
            width: 24px;
            object-fit: contain;
        }
    }

    .svgicon {
        //opacity: .5;
        @include svgicon-color(var(--sf-sidebar-nav-link-icon-color));
    }
}

.dropdownMenu__text {
    font-weight: var(--sf-font-weight-normal);
}

.dropdownMenu__nameAndTag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dropdownMenu__tag {
    display: flex;
    margin-inline-start: 24px;
}