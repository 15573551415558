@use "sass:math";
@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/containerMixins";
@import "../mixins/svgiconMixins";

.layoutHeaderMainBar {
    width: 100%;
    position: relative;
    box-sizing: content-box;
    background-color: var(--sf-layout-header-main-bar-bg-color);
    background-image: var(--sf-layout-header-main-bar-bg-image);
    background-position: var(--sf-layout-header-main-bar-bg-position);
    background-size: var(--sf-layout-header-main-bar-bg-size);
    background-repeat: var(--sf-layout-header-main-bar-bg-repeat);
    box-shadow: var(--sf-layout-header-main-bar-box-shadow);
    order: var(--sf-layout-header-main-bar-order);
    //@media (max-width: @screen-600px-1) {
    //    transition: height .5s @transition-timing-function;
    //    .layoutHeader.--active &, .layoutHeader.--up & {
    //        height: $layoutHeaderMainBar-inside-height;
    //    }
    //}
}

.layoutHeaderMainBar__container {
    @include container(var(--sf-layout-header-main-bar-container-max-width));
    display: flex;
}

.layoutHeaderMainBar__logoCell {
    display: flex;
    align-self: stretch;
    padding-inline-end: 32px !important;
    flex-basis: var(--sf-layout-header-logo-max-width);
    position: relative;
    padding-block: $layoutHeaderMainBar-default-padding;
    @media (min-width: $screen-1200px) {
        padding-block: var(--sf-layout-header-main-bar-padding);
    }

    @media (min-width: $screen-760px) {
        flex-basis: 25%;
    }
}

.layoutHeaderMainBar__logoCell__backdrop {
    position: absolute;
    display: var(--sf-layout-header-logocell-backdrop-display);
    height: 100%;
    width: 2000px;
    background-color: var(--sf-layout-header-logocell-backdrop-bg-color);
    background-image: var(--sf-layout-header-logocell-backdrop-bg-image);
    top: 0;
    right: var(--sf-layout-header-logocell-backdrop-inline-offset);
    border-radius: var(--sf-layout-header-logocell-backdrop-border-radius);
    box-shadow: var(--sf-layout-header-logocell-backdrop-box-shadow);
}

.layoutHeaderMainBar__logo {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-inline-start: var(--sf-layout-header-logo-padding-inline-start);

    a {
        display: flex;
        position: relative;
        width: 100%;
        height: calc(min(var(--sf-layout-header-logo-mobile-max-height), $layoutHeaderMainBar-default-height));
        align-items: stretch;
        justify-content: stretch;
        @media (min-width: $screen-760px) {
            width: unset;
            min-width: var(--sf-layout-header-logo-min-width);
            height: var(--sf-layout-header-logo-max-height);
        }
    }

    img {
        display: block;
        position: relative;
        max-height: 100%;
        max-width: 100%;
        flex: 1;
        object-fit: contain;
        object-position: left center;
        -webkit-user-drag: none;
        image-rendering: optimizeQuality;
        //image-rendering: -webkit-optimize-contrast; // For sharper edges in Chrome on mac.
        //transform: #{"translateZ(0)"}; // For better quality of scaled down image. Enables 3D graphic accelerated rendeing.
    }

    @media (min-width: $screen-1200px) {
        object-position: var(--sf-layout-header-logo-desktop-inline-position) center;
    }
}

.layoutHeaderMainBar__logo__backdrop {
    position: absolute;
    display: var(--sf-layout-header-logo-backdrop-display);
    height: 100%;
    width: 2000px;
    background-color: var(--sf-layout-header-logo-backdrop-bg-color);
    background-image: var(--sf-layout-header-logo-backdrop-bg-image);
    top: 0;
    right: calc(100% - var(--sf-layout-header-logo-backdrop-inline-offset));
}

.layoutHeaderMainBar__mainCell {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: $layoutHeaderMainBar-default-padding;
    @media (min-width: $screen-760px) {
        flex-basis: 75%;
    }
    @media (min-width: $screen-1200px) {
        padding-top: var(--sf-layout-header-main-bar-padding);
    }
}

.layoutHeaderMainBar__menuBar {
    padding-bottom: var(--sf-layout-header-main-bar-gap);
    display: none;
    @media (min-width: $screen-1200px) {
        display: var(--sf-layout-header-main-bar-menu-bar-display);
    }
}

.layoutHeaderMainBar__menuBar__content {
    display: flex;
    line-height: 36px;
    height: 36px;
    justify-content: space-between;
}


.layoutHeaderMainBar__controlsBar {
    display: flex;
}


.layoutHeaderMainBar__searchCell {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    padding-bottom: $layoutHeaderMainBar-default-padding;
    @media (min-width: $screen-1200px) {
        padding-bottom: var(--sf-layout-header-main-bar-padding);
    }

    html.--hideLayoutHeaderMenuBarAndSearch & {
        visibility: hidden;
        pointer-events: none;
    }

    @media (max-width: $screen-760px-1) {
        position: absolute;
        left: 0;
        top: $layoutHeaderMainBar-default-height + 3 * $layoutHeaderMainBar-default-padding;
        width: 100%;
        //transition: opacity .5s $transition-timing-function, visibility .5s $transition-timing-function, transform .5s $transition-timing-function;
        //.layoutHeader.--active &, .layoutHeader.--up & {
        //    @media (max-width: ($screen-520px - 1px)) {
        //        transform: translateY(-$layoutHeaderMainBar-inside-height);
        //    }
        //    opacity: 0;
        //    visibility: hidden;
        //}
        html.--hideLayoutHeaderMenuBarAndSearch * {
            display: none;
        }
    }
}

.layoutHeaderMainBar__controlsSpacer {
    flex-grow: 1;
    @media (min-width: $screen-760px) {
        flex-grow: 0;
    }
}


.layoutHeaderMainBar__buttonsCell {
    @media (min-width: $screen-760px) {
        flex-basis: 33.33%;
    }
    display: flex;
    justify-content: flex-end;
    gap: var(--sf-layout-header-main-bar-gap);
    padding-inline-start: var(--sf-layout-header-main-bar-gap);
}


.layoutHeaderMainBar__buttonCell {
    display: contents;
    align-self: stretch;
}

.layoutHeaderMainBar__buttonCell.--menu {
    @media (min-width: $screen-1200px-1) {
        display: var(--sf-layout-header-main-bar-button-menu-desktop-display);
    }
}

.layoutHeaderMainBar__button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: var(--sf-layout-header-main-bar-button-width);
    min-width: 40px;
    cursor: pointer;
    color: var(--sf-layout-header-main-bar-button-color);
    border: none;
    background-color: transparent;
    padding-bottom: $layoutHeaderMainBar-default-padding;
    @media (min-width: $screen-1200px) {
        padding-bottom: var(--sf-layout-header-main-bar-padding);
    }
}

.layoutHeaderMainBar__button__inner {
    align-self: stretch;
    border-radius: var(--sf-border-radius);
    @media (min-width: $screen-1200px) {
        background-color: var(--sf-layout-header-main-bar-button-bg-color);
        box-shadow: var(--sf-layout-header-main-bar-button-box-shadow);
    }

    .layoutHeaderMainBar__button.--highlighted & {
        color: var(--sf-layout-header-main-bar-button-highlighted-color);
    }

    .layoutHeaderMainBar__button.--active & {
        color: var(--sf-layout-header-main-bar-button-hover-color);
        @media (min-width: $screen-1200px) {
            background-color: var(--sf-layout-header-main-bar-button-hover-bg-color);
        }
    }

    @media (hover) {
        .layoutHeaderMainBar__button:hover & {
            color: var(--sf-layout-header-main-bar-button-hover-color);
            @media (min-width: $screen-1200px) {
                background-color: var(--sf-layout-header-main-bar-button-hover-bg-color);
                box-shadow: var(--sf-layout-header-main-bar-button-hover-box-shadow);
            }
        }
    }

    display: flex;
    justify-content: center;
    flex-basis: 100%;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: $layoutHeaderMainBar-default-height;
    gap: 2px;
}

.layoutHeaderMainBar__button__label {
    display: none;
    line-height: normal;
    font-size: 11px;
    font-weight: var(--sf-font-weight-semibold);
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    opacity: var(--sf-layout-header-main-bar-button-label-opacity);
    @media (min-width: $screen-1200px) {
        display: var(--sf-layout-header-main-bar-button-label-desktop-display);
    }
}


.layoutHeaderMainBar__button__icon {
    background-color: var(--sf-layout-header-main-bar-button-iconcell-bg-color);
    border-radius: var(--sf-layout-header-main-bar-button-iconcell-border-radius);
    height: var(--sf-layout-header-main-bar-button-iconcell-size);
    aspect-ratio: 1 / 1;
    justify-content: center;
    align-items: center;
    display: flex;


    .layoutHeaderMainBar__button.--highlighted & {
        background-color: var(--sf-layout-header-main-bar-button-highlighted-iconcell-bg-color);
    }

    @media (hover) {
        .layoutHeaderMainBar__button:hover & {
            background-color: var(--sf-layout-header-main-bar-button-hover-iconcell-bg-color);
        }
    }

    .layoutHeaderMainBar__button.--active & {
        background-color: var(--sf-layout-header-main-bar-button-hover-iconcell-bg-color);
    }

    .svgicon {
        @include svgicon-size(24px);
    }

    img {
        width: 24px;
        height: 24px;
        display: block;
        border-radius: 50%;
        box-shadow: var(--sf-block-shadow);
        background-size: cover;
    }
}


.layoutHeaderButton__hamburgerIcon {
    display: block;
    position: relative;
    width: 24px;
    height: 24px;

    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background-color: currentColor !important;
        opacity: 1;
        left: 0;
        transition: transform .5s $transition-timing-function, opacity .5s $transition-timing-function;
        border-radius: 1px;
        top: unquote("calc(50% - 1px)");

        &:nth-child(1) {
            transform: translateY(-8px);
        }

        &:nth-child(4) {
            transform: translateY(8px);
        }

        .layoutHeaderMainBar__button.--active & {
            &:nth-child(1), &:nth-child(4) {
                transform: translateY(0) scale(0);
                opacity: 0;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }
}


.layoutHeaderMainBar__button__badge {
    position: absolute;
    left: 45%;
    top: 0;
    display: block;
    border-radius: var(--sf-border-radius);;
    line-height: 16px;
    height: 16px;
    width: 16px;
    overflow: hidden;
    text-align: center;
    background-color: $color-red;
    color: white;
    font-weight: var(--sf-font-weight-bold);
    font-size: .6rem;
    box-shadow: 0 0 4px $color-red;
    @media (min-width: $screen-1200px) {
        display: none;
    }
}


.layoutHeaderIdentity {
    line-height: $line-height;
    padding: 12px 0 20px 40px;
    margin-inline-start: 12px;
    position: relative;

    &:not(:first-child) {
        margin-top: 1rem;
    }

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    @media (min-width: $screen-760px) {
        margin: 0 1rem 0;
    }

    strong {
        display: block;
        font-weight: var(--sf-font-weight-bold);
    }

    em {
        display: block;
        font-style: normal;
        font-size: $font-size-088rem;
        color: rgba(#232323, math.div(50%, 100.0%));
    }

    .layoutHeaderIdentity__profileImage {
        position: absolute;
        border-radius: var(--sf-border-radius);;
        height: 24px;
        width: 24px;
        top: 12px;
        left: 0;

        .svgicon {
            @include svgicon-color(var(--sf-sidebar-nav-link-icon-color));
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    .layoutHeaderIdentity__bonusPoints {
        margin-top: 16px;

        .svgicon {
            @include svgicon-size(20px);
            @include svgicon-color(var(--sf-sidebar-nav-link-icon-color));
        }
    }

    .close {
        position: absolute;
        top: -5px;
        left: 15px;
        font-size: 34px;
        font-weight: bold;
        color: rgba(#232323, math.div(50%, 100.0%));

        @media (hover) {
            &:hover {
                color: var(--sf-color-primary);
            }
        }
    }
}