@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/buttonLinkMixins";
@import "../mixins/svgiconMixins";

$vehicleSelection-select-padding: 12px;

.vehicleSelection {
    user-select: none;
}

.vehicleSelection__desktop {
    display: none;
    @media (min-width: $screen-900px) {
        display: block;
    }
}

.vehicleSelection__vehicleUnselect {
    @include btnLinkUnderlinedLightTextColor();
    @include blockButton();
    @include blockButton--hoverable();
    flex-shrink: 0;
    height: var(--sf-vehicle-selection-height);
    width: var(--sf-vehicle-selection-height);
    margin-inline-end: 4px;
    &[disabled] {
        display: none;
    }
}

.vehicleSelection__form {
    align-items: stretch;
    display: flex;
}

.vehicleSelection__userVehicle {
    margin-bottom: 4px;
    margin-right: 4px;

    a {
        height: 100%;
    }

    @media (max-width: $screen-900px-1) {
        display: none;
    }
}

.vehicleSelection__vehicleTypes {
    display: grid;
    grid-auto-columns: 1fr;
    gap: 7px; // intentionally 7px because there is 1px separator
    margin-bottom: 4px;
    margin-inline-end: 4px;
    @media (min-width: $screen-1200px) {
        //flex-basis: calc(25% - 4px);
        flex-shrink: 0;
        flex-grow: 0;
    }
    @include blockButton();
}

.vehicleSelection__vehicleType {
    border-radius: calc(var(--sf-border-radius) - 2px);
    background-color: transparent;
    border: none;
    grid-row: 1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(var(--sf-vehicle-selection-height) - 2px);
    font-weight: var(--sf-font-weight-normal);
    font-size: $font-size-075rem;
    color: var(--sf-text-light-color);
    position: relative;

    .svgicon {
        opacity: .5;
        margin: 0 $vehicleSelection-select-padding;
    }

    @include blockButton(transparent);
    @include blockButton--hoverable();

    &.--selected {
        color: var(--sf-color-primary);

        .svgicon {
            opacity: 1;
        }
    }

    &:not(:first-child):before {
        width: 1px;
        height: 75%;
        content: '';
        position: absolute;
        left: -5px;
        top: 50%;
        transform: translateY(-50%);
        background-color: var(--sf-line-color-100);
    }
}

.vehicleSelection__vehicleType__label {
    padding: 0 4px;
    display: none;
    //@media (min-width: $screen-1200px) {
    //    display: block;
    //}
}

.vehicleSelection__selectSteps {
    flex-basis: 100%;
    display: grid;
    margin-bottom: 4px;
    grid-template-columns: 1fr;
    gap: 4px;
    padding-inline-end: 4px;
    @media (min-width: $screen-760px) {
        grid-template-columns: 2fr 3fr 3fr;
    }

    .svgicon {
        pointer-events: none;
    }
}

.vehicleSelection__selectStep {
    flex-grow: 1;
    flex-basis: 33%;
    display: block;
    cursor: pointer;
    border-radius: var(--sf-border-radius);
    position: relative;
    height: var(--sf-vehicle-selection-height);
    background-color: var(--sf-form-control-bg-color);

    label {
        font-weight: var(--sf-font-weight-semibold);
        font-size: $font-size-088rem;
        display: block;
        position: absolute;
        color: var(--sf-text-light-color);
        top: 6px;
        inset-inline-start: $vehicleSelection-select-padding;
    }

    i {
        inset-inline-start: $vehicleSelection-select-padding;
        bottom: 15%;
        position: absolute;
        font-size: $font-size-088rem;
        color: $color-red;
    }

    select {
        -webkit-appearance: none;
        border: none;
        padding: ($vehicleSelection-select-padding + 2px) (2 * $vehicleSelection-select-padding) 0 $vehicleSelection-select-padding;
        margin: 0;
        width: 100%;
        outline: none;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        font-weight: var(--sf-font-weight-bold);
        font-size: 1rem;
        cursor: pointer;
        border-radius: var(--sf-border-radius);

        &[disabled] {
            cursor: not-allowed;
            background-color: var(--sf-line-color-light);
        }

        &:not([disabled]) {
            @include blockButton();
            @include blockButton--hoverable();
            background-color: transparent !important;
        }

        option {
            font-size: 1rem;
        }
    }

    .svgicon {
        position: absolute;
        inset-inline-end: $vehicleSelection-select-padding;
        top: calc(50% - 6px);
        @include svgicon-size(12px);
        opacity: .5;
    }
}

.vehicleSelection__spinner {
    height: 100%;
    transform: scale(.5);
}

.vehicleSelection__formSubmit {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
}

.vehicleSelection__formSubmitButton {
    width: 80px;
    height: 40px;

    .svgicon {
        opacity: 1;
        transform: scale(.75);
    }

    @media (min-width: $screen-900px) {
        margin-inline-start: auto;
        width: var(--sf-vehicle-selection-height);
        height: var(--sf-vehicle-selection-height);
    }
}

.vehicleSelection__mobile {
    display: flex;
    @media (min-width: $screen-900px) {
        display: none;
    }
}

.vehicleSelection__mobile__button {
    @include blockButton();
    @include blockButton--hoverable();
    display: flex;
    height: var(--sf-vehicle-selection-height);
    padding-inline: 8px;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
}

.vehicleSelection__mobile__button > .svgicon {
    opacity: .5;
    color: var(--sf-text-color);
    margin-inline-start: auto;
    @include svgicon-size(12px);
}

.vehicleSelection__mobile__image {
    align-self: stretch;
    width: 80px;
    object-fit: contain;
}

.vehicleSelection__mobile__vehicleText {
    display: block;
    overflow: hidden;
    white-space: nowrap;
}

.vehicleSelection__mobile__vehicleBrandModel {
    display: block;
    font-weight: var(--sf-font-weight-bold);
}

.vehicleSelection__mobile__vehicleName {
    display: block;
    color: var(--sf-text-light-color);
    font-size: $font-size-088rem;
}

.vehicleSelection__mobile__vehicleUnselect {
    @include btnLinkUnderlinedLightTextColor;
    flex-shrink: 0;
    margin-inline-start: 8px;
    &[disabled] {
        display: none;
    }
}

.vehicleSelection__mobile__selectPrompt {
    > span:nth-child(1) {
        display: block;
        color: var(--sf-text-light-color);
        font-size: $font-size-088rem;
    }
    > span:nth-child(2) {
        display: block;
        font-weight: var(--sf-font-weight-bold);
    }
}