:root {
    --sf-font-family: 'Inter';

    --sf-font-size-root: 14px;
    --sf-font-size-large: 16px;

    --sf-font-weight-normal: 400;
    --sf-font-weight-semibold: 600;
    --sf-font-weight-bold: 800;
    --sf-font-weight-extrabold: var(--sf-font-weight-bold);

    --sf-letter-spacing: 0;

    --sf-body-bg-color: white;
    --sf-body-backdrop-display: none;
    --sf-body-backdrop-bg-color: rgba(0, 0, 0, .75);
    --sf-body-backdrop-blur: 0;

    --sf-container-max-width: 1500px;

    --sf-modal-loader-bg-color: var(--sf-text-color);

    --sf-text-color: #232323;
    --sf-text-light-color: rgba(0, 0, 0, .5);

    --sf-block-shadow: 0 0 8px rgba(0, 0, 0, .15);
    --sf-modal-box-shadow: 0 8px 16px rgba(0, 0, 0, .1), 0 0 0 1px rgba(0, 0, 0, .15);
    --sf-modal-bg-color: white;

    --sf-border-radius: 4px;

    --sf-heading-1-color: var(--sf-text-color);
    --sf-heading-1-font-size: 2rem;
    --sf-heading-1-font-weight: var(--sf-font-weight-extrabold);
    --sf-heading-1-font-style: normal;
    --sf-heading-1-text-transform: none;
    --sf-heading-1-letter-spacing: inherit;
    --sf-heading-1-word-spacing: inherit;

    --sf-heading-2-color: var(--sf-text-color);
    --sf-heading-2-font-size: 1.35rem;
    --sf-heading-2-font-weight: var(--sf-font-weight-extrabold);
    --sf-heading-2-font-style: normal;
    --sf-heading-2-text-transform: none;
    --sf-heading-2-letter-spacing: inherit;
    --sf-heading-2-word-spacing: inherit;

    --sf-heading-3-color: var(--sf-text-color);
    --sf-heading-3-font-size: 1.175rem;
    --sf-heading-3-font-weight: var(--sf-font-weight-extrabold);
    --sf-heading-3-font-style: normal;
    --sf-heading-3-text-transform: none;
    --sf-heading-3-letter-spacing: inherit;
    --sf-heading-3-word-spacing: inherit;

    --sf-heading-4-color: var(--sf-text-color);
    --sf-heading-4-font-size: 1.1rem;
    --sf-heading-4-font-weight: var(--sf-font-weight-extrabold);
    --sf-heading-4-font-style: normal;
    --sf-heading-4-text-transform: none;
    --sf-heading-4-letter-spacing: inherit;
    --sf-heading-4-word-spacing: inherit;

    --sf-heading-5-font-size: 1rem;

    --sf-color-primary: #232323;
    --sf-color-primary-contrast: white;
    --sf-theme-color: transparent;
    --sf-theme-color-mobile: var(--sf-theme-color);

    --sf-color-eu-blue: #003399;
    --sf-color-eu-yellow: #FFCC00;

    --sf-hot-text-color: #E89535;
    --sf-hot-bg-color: rgba(232, 149, 53, 0.15);

    --sf-line-color-strong: rgba(0, 0, 0, .2);
    --sf-line-color-light: rgba(0, 0, 0, .085);
    --sf-line-color-100: rgba(0, 0, 0, .1);

    --sf-pane-bg-color: rgba(0, 0, 0, .04);
    --sf-skeleton-bg-color: rgba(0, 0, 0, .05);
    --sf-skeleton-fade-bg-color: var(--sf-body-bg-color);
    --sf-image-overlay-bg-color: rgba(0, 0, 0, .04);
    --sf-grid-nav-gap: 4px;
    --sf-grid-nav-gap-block: var(--sf-grid-nav-gap);

    --sf-block-button-box-shadow: none;
    --sf-block-button-border-color: var(--sf-line-color-100);
    --sf-block-button-bg-color: #f3f3f3;
    --sf-block-button-bg-border-color: var(--sf-block-button-bg-color);
    --sf-block-button-hover-bg-color: #dbdbdb;
    --sf-block-button-hover-border-color: var(--sf-block-button-hover-bg-color);
    --sf-block-button-hover-text-color: #5b5b5b;
    --sf-block-button-current-border-color: var(--sf-color-primary);

    --sf-block-button-highlighted-bg-color: var(--sf-color-primary);
    --sf-block-button-highlighted-border-color: var(--sf-color-primary);
    --sf-block-button-highlighted-text-color: var(--sf-color-primary-contrast);

    --sf-btn-default-bg-color: var(--sf-block-button-bg-color);
    --sf-btn-default-text-color: var(--sf-text-color);
    --sf-btn-default-hover-bg-color: var(--sf-block-button-hover-bg-color);
    --sf-btn-default-hover-text-color: var(--sf-block-button-hover-text-color);

    --sf-btn-primary-bg-color: var(--sf-color-primary);
    --sf-btn-primary-text-color: var(--sf-color-primary-contrast);
    --sf-btn-primary-bg-image: none;
    --sf-btn-primary-bg-size: 100% auto;
    --sf-btn-primary-bg-position: center top;
    --sf-btn-primary-bg-repeat: initial;
    --sf-btn-primary-box-shadow: none;
    --sf-btn-primary-hover-bg-color: var(--sf-btn-primary-bg-color);
    --sf-btn-primary-hover-text-color: var(--sf-btn-primary-text-color);
    --sf-btn-primary-hover-box-shadow: 0 2px 7px 0 var(--sf-btn-primary-hover-bg-color);
    --sf-btn-primary-font-size: 1rem;
    --sf-btn-primary-font-weight: var(--sf-font-weight-bold);
    --sf-btn-primary-font-style: normal;
    --sf-btn-primary-text-transform: none;
    --sf-btn-primary-letter-spacing: inherit;
    --sf-btn-primary-word-spacing: inherit;
    --sf-btn-primary-icon-opacity: 1;

    --sf-btn-height: 44px;
    --sf-btn-sm-height: 32px;
    --sf-btn-xs-height: 24px;
    --sf-btn-lg-height: 48px;
    --sf-btn-xlg-height: 56px;
    --sf-btn-min-width: 160px;

    --sf-license-plate-border-radius: 6px;
    --sf-license-plate-height: 56px;

    --sf-link-color: var(--sf-color-primary);
    --sf-link-hover-color: var(--sf-color-primary);

    --sf-form-control-bg-color: white;
    --sf-form-control-bg-image: transparent;
    --sf-form-control-border-color: rgba(0, 0, 0, .15);
    --sf-form-control-focus-border-color: #232323;
    --sf-form-control-text-color: #232323;
    --sf-form-control-placeholder-color: rgba(35, 35, 35, 0.35);
    --sf-form-control-height: 44px;

    --sf-layout-header-bg-image: none;
    --sf-layout-header-bg-color: transparent;
    --sf-layout-header-bg-position: center top;
    --sf-layout-header-bg-size: 100% auto;
    --sf-layout-header-bg-repeat: initial;
    --sf-layout-header-box-shadow: none;
    --sf-layout-header-logo-padding-inline-start: 0;
    --sf-layout-header-logo-max-height: 48px;
    --sf-layout-header-logo-mobile-max-height: var(--sf-layout-header-logo-max-height);
    --sf-layout-header-logo-min-width: 120px;
    --sf-layout-header-logo-max-width: 264px;
    --sf-layout-header-logo-desktop-inline-position: 0;
    --sf-layout-header-logo-backdrop-display: none;
    --sf-layout-header-logo-backdrop-bg-color: transparent;
    --sf-layout-header-logo-backdrop-bg-image: none;
    --sf-layout-header-logo-backdrop-inline-offset: 0;
    --sf-layout-header-logocell-backdrop-display: none;
    --sf-layout-header-logocell-backdrop-bg-color: transparent;
    --sf-layout-header-logocell-backdrop-bg-image: none;
    --sf-layout-header-logocell-backdrop-inline-offset: 12px;
    --sf-layout-header-logocell-backdrop-border-radius: 0;
    --sf-layout-header-logocell-backdrop-box-shadow: none;

    --sf-layout-header-main-bar-container-max-width: var(--sf-container-max-width);
    --sf-layout-header-main-bar-order: 0;
    --sf-layout-header-main-bar-bg-color: white;
    --sf-layout-header-main-bar-padding: 12px;
    --sf-layout-header-main-bar-gap: 6px;
    --sf-layout-header-main-bar-box-shadow: 0 0 0 1px rgba(0, 0, 0, .05);
    --sf-layout-header-main-bar-bg-image: none;
    --sf-layout-header-main-bar-bg-size: 100% auto;
    --sf-layout-header-main-bar-bg-position: center top;
    --sf-layout-header-main-bar-bg-repeat: initial;
    --sf-layout-header-main-bar-button-color: var(--sf-text-color);
    --sf-layout-header-main-bar-button-bg-color: transparent;
    --sf-layout-header-main-bar-button-box-shadow: none;
    --sf-layout-header-main-bar-button-label-opacity: .75;
    --sf-layout-header-main-bar-button-label-desktop-display: block;
    --sf-layout-header-main-bar-button-width: 100%;
    --sf-layout-header-main-bar-button-iconcell-size: 24px;
    --sf-layout-header-main-bar-button-iconcell-border-radius: var(--sf-border-radius);
    --sf-layout-header-main-bar-button-iconcell-bg-color: transparent;
    --sf-layout-header-main-bar-button-highlighted-color: #E89535;
    --sf-layout-header-main-bar-button-highlighted-iconcell-bg-color: var(--sf-layout-header-main-bar-button-iconcell-bg-color);
    --sf-layout-header-main-bar-button-hover-color: var(--sf-color-primary);
    --sf-layout-header-main-bar-button-hover-bg-color: var(--sf-layout-header-main-bar-button-bg-color);
    --sf-layout-header-main-bar-button-hover-box-shadow: var(--sf-layout-header-main-bar-button-box-shadow);
    --sf-layout-header-main-bar-button-hover-iconcell-bg-color: var(--sf-layout-header-main-bar-button-iconcell-bg-color);
    --sf-layout-header-main-bar-button-menu-desktop-display: none;
    --sf-layout-header-main-bar-menu-bar-display: none;

    --sf-layout-header-search-height: 48px;
    --sf-layout-header-search-bg-clip: border-box;
    --sf-layout-header-search-bg-color: rgba(255, 255, 255, .5);
    --sf-layout-header-search-bg-color-contrast: black;
    --sf-layout-header-search-border-color: rgba(0, 0, 0, .2);
    --sf-layout-header-search-border-radius: var(--sf-border-radius);
    --sf-layout-header-search-box-shadow: var(--sf-block-shadow);
    --sf-layout-header-search-border-focus-color: black;
    --sf-layout-header-search-bg-focus-color: rgba(255, 255, 255, .9);
    --sf-layout-header-search-bg-focus-color-contrast: black;
    --sf-layout-header-search-focus-box-shadow: 0 0 0 1px var(--sf-layout-header-search-border-focus-color), var(--sf-block-shadow);
    --sf-layout-header-search-placeholder-text-align: center;
    --sf-layout-header-search-mobile-bg-color: var(--sf-form-control-bg-color);
    --sf-layout-header-search-mobile-border-color: var(--sf-form-control-border-color);
    --sf-layout-header-search-mobile-text-color: var(--sf-form-control-text-color);

    --sf-layout-header-menu-bar-container-max-width: var(--sf-container-max-width);
    --sf-layout-header-menu-bar-display: block;
    --sf-layout-header-menu-bar-link-color: var(--sf-text-color);
    --sf-layout-header-menu-bar-link-hover-color: var(--sf-color-primary);
    --sf-layout-header-menu-bar-link-text-shadow: none;
    --sf-layout-header-menu-bar-link-icon-color: var(--sf-layout-header-menu-bar-link-color);
    --sf-layout-header-menu-bar-link-icon-opacity: .4;
    --sf-layout-header-menu-bar-link-arrow-color: var(--sf-layout-header-menu-bar-link-color);
    --sf-layout-header-menu-bar-link-font-size: .9rem;
    --sf-layout-header-menu-bar-link-font-weight: var(--sf-font-weight-semibold);
    --sf-layout-header-menu-bar-link-text-transform: none;
    --sf-layout-header-menu-bar-link-font-style: normal;
    --sf-layout-header-menu-bar-link-letter-spacing: var(--sf-letter-spacing);
    --sf-layout-header-menu-bar-bg-color: white;
    --sf-layout-header-menu-bar-bg-image: none;
    --sf-layout-header-menu-bar-box-shadow: 0 0 0 1px rgba(0, 0, 0, .05);
    --sf-layout-header-menu-bar-height: 48px;
    --sf-layout-header-menu-bar-content-bg-color: transparent;
    --sf-layout-header-menu-bar-content-bg-image: none;
    --sf-layout-header-menu-bar-content-box-shadow: none;
    --sf-layout-header-menu-bar-spacer-display: none;
    --sf-layout-header-menu-bar-separator-width: 2px;
    --sf-layout-header-menu-bar-separator-height: 100%;
    --sf-layout-header-menu-bar-separator-color: transparent;
    --sf-layout-header-menu-bar-separator-margin-inline: 8px;
    --sf-layout-header-menu-bar-separator-transform: none;

    --sf-layout-header-page-bg-color: transparent;
    --sf-layout-header-page-bg-image: none;
    --sf-layout-header-page-box-shadow: none;
    --sf-layout-header-page-padding-block: 8px;


    --sf-layout-footer-bg-color: var(--sf-block-button-bg-color);
    --sf-layout-footer-bg-image: none;
    --sf-layout-footer-bg-size: 100% auto;
    --sf-layout-footer-bg-position: center top;
    --sf-layout-footer-bg-repeat: initial;
    --sf-layout-footer-text-color: var(--sf-text-color);
    --sf-layout-footer-box-shadow: 0 0 0 0 var(--sf-layout-footer-bg-color);
    --sf-layout-footer-payment-logos-display: block;

    --sf-layout-footer-tecalliance-tecdoc-inside-logo-color-display: block;
    --sf-layout-footer-tecalliance-tecdoc-inside-logo-mono-display: none;
    --sf-layout-footer-tecalliance-tecdoc-inside-logo-mono-color: var(--sf-layout-footer-text-color);
    --sf-layout-footer-tecalliance-tecdoc-inside-logo-mono-opacity: .65;

    --sf-layout-footer-page-bg-color: var(--sf-layout-footer-bg-color);
    --sf-layout-footer-page-text-color: var(--sf-layout-footer-text-color);

    --sf-layout-header-menu-bar-car-vehicle-type-display: block;
    --sf-layout-header-menu-bar-utility-vehicle-type-display: none;
    --sf-layout-header-menu-bar-motorcycle-vehicle-type-display: block;
    --sf-layout-header-menu-bar-all-categories-display: block;

    --sf-breadcrumb-height: 40px;

    --sf-sidebar-nav-box-shadow: none;
    --sf-sidebar-nav-bg-color: transparent;
    --sf-sidebar-nav-link-text-color: var(--sf-color-primary);
    --sf-sidebar-nav-link-icon-color: var(--sf-sidebar-nav-link-text-color);
    --sf-sidebar-nav-link-root-height: 56px;
    --sf-sidebar-nav-link-root-padding-inline-start: 8px;
    --sf-sidebar-nav-link-root-font-size: 1rem;
    --sf-sidebar-nav-link-icon-size: 32px;
    --sf-sidebar-nav-link-root-font-weight: var(--sf-font-weight-semibold);
    --sf-sidebar-nav-name-suffix-display: none;
    --sf-sidebar-nav-name-suffix-text-color: var(--sf-text-light-color);
    --sf-sidebar-nav-node-root-in-current-path-bg-color: var(--sf-pane-bg-color);
    --sf-sidebar-nav-separator-color: var(--sf-line-color-light);
    --sf-sidebar-nav-opener-inline-start-bool: 0;
    --sf-sidebar-nav-opener-width: 32px;

    --sf-page-part-category-grid-btn-min-height: 64px;
    --sf-page-part-category-grid-icon-left-size: 48px;
    --sf-page-part-category-grid-icon-above-size: 64px;
    --sf-page-part-category-grid-icon-above-button-height: 128px;
    --sf-page-part-category-grid-icon-above-aspect-ratio: auto;
    --sf-page-part-category-grid-border-color: var(--sf-block-button-border-color);
    --sf-page-part-category-grid-bg-color: white;
    --sf-page-part-category-grid-text-color: var(--sf-text-color);
    --sf-page-part-category-grid-overlay-bg-color: transparent;
    --sf-page-part-category-grid-name-name-text-transform: none;
    --sf-page-part-category-grid-name-name-font-style: normal;
    --sf-page-part-category-grid-name-name-font-weight: var(--sf-font-weight-semibold);
    --sf-page-part-category-grid-name-name-letter-spacing: inherit;
    --sf-page-part-category-grid-name-name-word-spacing: inherit;

    --sf-page-part-vehicle-grid-column-count: 8;

    --sf-page-part-image-slider-slide-filter: none;
    --sf-page-part-image-slider-slide-transform: none;
    --sf-page-part-image-slider-slide-opacity: 1;
    --sf-page-part-image-slider-slide-transition-duration: 0;

    --sf-page-part-vehicle-search-background-color: var(--sf-pane-bg-color);
    --sf-page-part-vehicle-search-text-color: var(--sf-text-color);

    --sf-product-name-link-text-color: var(--sf-link-color);
    --sf-product-prices-your-price-bg-color: transparent;
    --sf-product-prices-your-price-discount-bg-color: #FFCC0040;
    --sf-product-special-info-text-color: #E89535;
    --sf-product-collection-bg-color: transparent;
    --sf-product-grid-product-gap-block: 40px;
    --sf-product-inline-gap-block: 24px;

    --sf-vehicle-model-vehicle-min-width: 300px;
    --sf-vehicle-selection-height: 48px;

    --sf-page-message-text-align: left;

    --sf-vehicle-manufacturer-list-logo-display: none;

    --sf-article-list-article-image-before-left: 0;
    --sf-article-list-article-image-before-top: 0;
    --sf-article-list-article-image-before-bg: none;
    --sf-article-list-article-image-before-transform: none;
    --sf-article-list-article-image-before-transform-origin: initial;
    --sf-article-list-article-image-after-left: 0;
    --sf-article-list-article-image-after-top: 0;
    --sf-article-list-article-image-after-bg: none;
    --sf-article-list-article-image-after-transform: none;
    --sf-article-list-article-image-after-transform-origin: initial;

    --sf-tag-height: 20px;

    --sf-tag-black-friday-text-color: yellow;
    --sf-tag-black-friday-bg-color: black;
    --sf-tag-new-bg-color: white;
    --sf-tag-new-text-color: #59C736;
    --sf-tag-inAction-bg-color: #e30613;
    --sf-tag-inAction-text-color: white;
    --sf-tag-freeShipping-bg-color: #59C736;
    --sf-tag-freeShipping-text-color: white;
    --sf-tag-discount-bg-color: #e30613;
    --sf-tag-discount-text-color: white;
    --sf-tag-discount-percents-height: var(--sf-tag-height);
    --sf-tag-discount-percents-mobile-height: var(--sf-tag-height);
    --sf-tag-discount-percents-bg-color: #e30613;
    --sf-tag-discount-percents-text-color: white;
    --sf-tag-discount-percents-font-size: 1rem;
    --sf-tag-sale-bg-color: #E89535;
    --sf-tag-sale-text-color: white;
    --sf-tag-bazaar-bg-color: #232323;
    --sf-tag-bazaar-text-color: white;
    --sf-tag-bestseller-bg-color: #e30613;
    --sf-tag-bestseller-text-color: white;
    --sf-tag-recommended-bg-color: #e30613;
    --sf-tag-recommended-text-color: white;
    --sf-tag-comingSoon-bg-color: #232323;
    --sf-tag-comingSoon-text-color: white;
    --sf-tag-mostWatched-bg-color: #232323;
    --sf-tag-mostWatched-text-color: white;
    --sf-tag-bundle-bg-color: #232323;
    --sf-tag-bundle-text-color: white;

    --sf-tag-fastest-bg-color: white;
    --sf-tag-fastest-text-color: #59C736;
    --sf-tag-fastest-border-color: #59C736;

    --sf-tag-cheapest-bg-color: white;
    --sf-tag-cheapest-text-color: #E89535;
    --sf-tag-cheapest-border-color: #E89535;

    --sf-availability-label-available-text-color: #59C736;
    --sf-availability-label-able-to-order-text-color: #009492;
    --sf-availability-label-on-request-text-color: #E89535;

    --sf-availability-label-red-text-color: #e30613;
    --sf-availability-label-orange-text-color: #E89535;
    --sf-availability-label-green-text-color: #59C736;
    --sf-availability-label-teal-text-color: #009492;
    --sf-availability-label-blue-text-color: #005bff;

    --sf-supply-option-cheapest-bg-color: rgba(232, 149, 53, .1);
    --sf-supply-option-fastest-bg-color: rgba(89, 199, 54, .1);

    --sf-coupon-bg-color: #e30613;
    --sf-coupon-text-color: white;
    --sf-coupon-border-color: transparent;

    --sf-vehicle-brand-grid-type-car-display: block;
    --sf-vehicle-brand-grid-type-motorcycle-display: block;
    --sf-vehicle-brand-grid-type-utility-display: block;
    --sf-vehicle-brand-grid-item-aspect-ratio: auto;

    --sf-mobile-app-header-height: 32px;
    --sf-mobile-app-header-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.1);
    --sf-mobile-app-header-link-color: var(--sf-link-color);

    --sf-mobile-app-nav-bg-color: var(--sf-body-bg-color);
    --sf-mobile-app-nav-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 0, 0, 0.1);
    --sf-mobile-app-nav-button-text-color: var(--sf-text-color);
    --sf-mobile-app-nav-button-active-text-color: var(--sf-color-primary);

    --sf-category-children-nav-list-display: block;

    --sf-tag-category-text-color: white;
    --sf-tag-category-bg-color: #e30613;
    --sf-tag-category-box-shadow: var(--sf-block-shadow);
    --sf-tag-category-font-weight: var(--sf-font-weight-semibold);
}