@import "../constants";
@import "../mixins/svgiconMixins";

.pagePartNews {
}

.pagePartNews__heading {
    // cross style fot .heading .heading--h2
    padding-bottom: 16px;
    border-bottom: 1px solid var(--sf-line-color-100);
    margin-bottom: 16px;
}

.pagePartNews__articles {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @media (min-width: $screen-600px) {
        grid-template-columns: repeat(2, 1fr);
    }
    gap: 16px;
}

.pagePartNews__article {
    position: relative;
    border-bottom: 1px solid var(--sf-line-color-light);
    padding-bottom: 24px;
}

.pagePartNews__article__link {
    display: block;
}


.pagePartNews__article__header {
    display: flex;
    padding-bottom: 4px;
}

.pagePartNews__article__heading {
    color: inherit;
    .pagePartNews__article__link:hover & {
        text-decoration: underline;
    }
}

.pagePartNews__article__time {
    font-size: $font-size-075rem;
    opacity: .65;
    white-space: nowrap;
    line-height: 1;
    cursor: help;
    padding-inline-start: 16px;
    color: var(--sf-text-light-color);
    align-self: center;
}

.pagePartNews__article__body {
    display: flex;
}

.pagePartNews__article__perex {
    margin: 0;
    line-height: $line-height;
    height: 3 * $line-height;
    opacity: .75;
    display: block;
    font-weight: var(--sf-font-weight-normal);
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--sf-text-color);
    padding-inline-end: 2rem;
    text-decoration: none;
    font-size: .9rem;
    flex-grow: 1;
}

.pagePartNews__article__arrow {
    align-self: stretch;
    display: flex;
    align-items: center;
    flex-grow: 0;
    .svgicon {
        @include svgicon-size(16px);
        opacity: .5;
    }
}

.pagePartNews__next {
    padding-bottom: 8px;
    .svgicon {
        transform: scale(.5) translateX(50%);
    }
}