@import "../constants";
@import "../mixins/headingMixins";
@import "../mixins/svgiconMixins";

.pageHeader {
    position: relative;
    line-height: $line-height-120;
    margin: 0 0 16px;
    display: flex;
    align-items: center;
}

.pageHeader--center {
    text-align: center;
    justify-content: center;
}

.pageHeader--underlined {
    border-bottom: 1px solid var(--sf-line-color-strong);
    padding-bottom: 16px;
}

.pageHeader__heading {
    @include heading;
    @include heading--h1;

    &--h2 {
        @include heading--h2;
    }

    // Do not set display:flex because of .balance-text
    line-height: 1;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}

.pageHeader__text {
    //font-size: $font-size-110rem;
    font-weight: var(--sf-font-weight-normal);
    margin-top: 10px;
    opacity: .5;
    @media (max-width: $screen-600px-1) {
        font-size: .8rem;
    }
}

.pageHeader__back {
    display: none;
    //display: inline-block;
    margin-top: .25rem;
    font-size: $font-size-088rem;

    .svgicon {
        margin-right: 0.7em;
        @include svgicon-size(0.7em);
        opacity: .5;
    }

    opacity: .5;

    @media (max-width: $screen-600px-1) {
        display: none;
        margin-top: .5rem;
        font-size: $font-size-075rem;
        position: absolute;
        top: 0;
        left: 0;
    }
    @media (min-width: $screen-600px) {
        text-decoration: underline;
    }
}

.pageHeader__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    picture {
        display: contents;
    }
    @media (max-width: $screen-600px-1) {
        display: none;
    }
    @media (min-width: $screen-600px) {
        img {
            max-height: 40px;
            max-width: 100px;
        }
    }
}