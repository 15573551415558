@import "../constants";

@mixin container($containerMaxWidth: var(--sf-container-max-width)) {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100vw - 24px);
    @media (min-width: $screen-600px) {
        max-width: calc(100vw - 48px);
    }
    @media (min-width: $screen-900px) {
        max-width: calc(min(100vw - 96px, $containerMaxWidth));
    }
}