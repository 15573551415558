@import "../constants";
@import "../mixins/buttonLinkMixins";
@import "../mixins/blockButtonMixins";
@import "../mixins/imageMixins";


.vehicleSchema {
    display: grid;
    gap: 32px;
    @media (min-width: $screen-desktop) {
        grid-template-columns: 1fr 1fr;
        //grid-template-columns: 1fr var(--layout-main-column-width-xlg);
    }
}

.vehicleSchema__image {
    user-select: none;
    @include imageOverlay();
    position: relative;

    img {
        width: 100%;
        display: block;
    }

    span {
        position: absolute;

        &:after {
            position: absolute;
            content: '';
            height: 16px;
            width: 16px;
            top: calc(50% - 8px);
            left: calc(50% - 8px);
            border-radius: 50%;
            background-color: red;
            opacity: .5;
        }
    }

    button {
        @include btnLinkReset;
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 24px;
        height: 24px;
        border-radius: var(--sf-border-radius);
        transform: translate(-50%, -50%);
        //transform: translate(-45%, -45%);
        z-index: 1;
        border: 1px solid transparent;
        box-shadow: 0 0 4px rgba(black, .2);
        background-color: white;
        justify-content: center;
        font-size: 1.1rem;
        font-weight: var(--sf-font-weight-bold);
        padding-inline: 6px;
        box-sizing: border-box;
        font-size: 0;
        @media (min-width: $screen-900px) {
            min-width: 40px;
            height: 40px;
        }
        opacity: 0;

        &.--blink {
            @keyframes vehicleSchemaAreaBlink {
                from {
                    background-color: rgba(black, 1);
                    color: white;
                }
                to {
                    background-color: rgba(white, 1);
                    color: black;
                }
            }
            animation: vehicleSchemaAreaBlink 1s $transition-timing-function 0s;
        }

        @include blockButton--bgHoverable(black, white);

        &.--active {
            @include blockButton--bgCurrent(black);
            color: white;
            z-index: 2;
        }
    }
}

.vehicleSchema__partTable {
    //border-collapse: collapse;
    width: 100%;

    thead {
        font-size: $font-size-088rem;
        color: var(--sf-text-light-color);
        font-weight: var(--sf-font-weight-semibold);
    }

    tr {
        text-align: left;
        line-height: 24px;

        td {
            padding-block: 8px;
            border-bottom: 1px solid var(--sf-line-color-light);
            vertical-align: top;

            &:not(:last-child) {
                padding-inline-end: 12px;
            }
        }

        h5 {
            font-weight: var(--sf-font-weight-normal);
            line-height: inherit;
        }
    }
}

.vehicleSchema__partTable__cellShring {
    width: 1%;
    white-space: nowrap;
}

.vehicleSchema__part__nameLink {
    @include linkUnderlineOnHover();
    flex-wrap: wrap;
    gap: 0 8px;

    strong {
        white-space: nowrap;
    }
}

.vehicleSchema__partTable__imageAreaCode {
    border-radius: var(--sf-border-radius);
    background-color: var(--sf-text-color);
    color: var(--sf-body-bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    height: 24px;
    padding-inline: 4px;
    box-sizing: border-box;
    font-weight: var(--sf-font-weight-semibold);
}

.vehicleSchema__part__unitCount {
    padding-inline: 8px;
    white-space: nowrap;
}

.vehicleSchema__part__btn {
    //@include btnLinkUnderlinedSubtleLine;
    @include btnPrimary;
    @include btnExtraSmall;
    flex-shrink: 0;
    white-space: nowrap;
}

.vehicleSchema__partDropdown {
    padding: 16px;
    width: calc(min(var(--layout-main-column-width-lg, 375px), 100dvw));

    .formatedText {
        font-size: $font-size-088rem;
        margin-top: 8px;
    }

    a {
        margin-top: 16px;
        width: 100%;
    }
}