@import "../constants";
@import "../mixins/containerMixins";


.layoutHeaderMenuBar {
    width: 100%;
    position: relative;
    background-color: var(--sf-layout-header-menu-bar-bg-color);
    background-image: var(--sf-layout-header-menu-bar-bg-image);
    background-position: center top;
    background-size: 100% auto;
    box-shadow: var(--sf-layout-header-menu-bar-box-shadow);
    //box-shadow: $block-shadow-default, 0 0 2px 0 rgba(0, 0, 0, .15);
    //transform-origin: center top;
    //transition: transform .5s $transition-timing-function;
    //
    //.layoutHeader.--up:not(.--active) & {
    //    transform: translateY(-$layoutHeaderMenuBar-height - 1px);
    //}
    display: var(--sf-layout-header-menu-bar-display);
    @media (max-width: $screen-1200px-1) {
        display: none;
    }

    html.--hideLayoutHeaderMenuBarAndSearch & {
        display: none;
    }
}

.layoutHeaderMenuBar__container {
    @include container(var(--sf-layout-header-menu-bar-container-max-width));
}

.layoutHeaderMenuBar__content {
    height: var(--sf-layout-header-menu-bar-height);
    border-radius: var(--sf-border-radius);
    background-color: var(--sf-layout-header-menu-bar-content-bg-color);
    background-image: var(--sf-layout-header-menu-bar-content-bg-image);
    box-shadow: var(--sf-layout-header-menu-bar-content-box-shadow);
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    scrollbar-width: none;
    overflow-x: auto;
    touch-action: pan-x;

    &::-webkit-scrollbar {
        display: none;
    }
}

.layoutHeaderMenuBar__spacer {
    flex-grow: 1;
    display: var(--sf-layout-header-menu-bar-spacer-display);
}

.layoutHeaderMenuBar__separator {
    display: block;
    width: var(--sf-layout-header-menu-bar-separator-width);
    height: var(--sf-layout-header-menu-bar-separator-height);
    align-self: center;
    color: var(--sf-layout-header-menu-bar-separator-color);
    background-color: currentColor;
    margin: 0 var(--sf-layout-header-menu-bar-separator-margin-inline);
    transform: var(--sf-layout-header-menu-bar-separator-transform);

    .layoutHeaderMainBar__menuBar__content > &:last-child,
    .layoutHeaderMenuBar__content > &:last-child {
        display: none;
    }
}

.layoutHeaderMenuBar__link {
    padding: 0 12px;
    white-space: nowrap;
    font-size: var(--sf-layout-header-menu-bar-link-font-size);
    font-weight: var(--sf-layout-header-menu-bar-link-font-weight);
    text-transform: var(--sf-layout-header-menu-bar-link-text-transform);
    font-style: var(--sf-layout-header-menu-bar-link-font-style);
    letter-spacing: var(--sf-layout-header-menu-bar-link-letter-spacing);
    color: var(--sf-layout-header-menu-bar-link-color);
    display: flex;
    align-items: center;
    line-height: 1;
    cursor: pointer;
    border: none;
    background: transparent none;
    height: 100%;
    text-shadow: var(--sf-layout-header-menu-bar-link-text-shadow);

    &.--button {
        font-size: .75em;
        position: relative;

        &:after {
            position: absolute;
            content: '';
            left: 0;
            top: 4px;
            right: 0;
            bottom: 4px;
            border: 1px solid var(--sf-layout-header-menu-bar-link-color);
            opacity: .4;
            border-radius: var(--sf-border-radius);
        }
    }

    @media (hover) {
        &:hover, &:active {
            color: var(--sf-layout-header-menu-bar-link-hover-color) !important;
            text-decoration: underline;
            text-underline-offset: .5ex;

            &.--button:after {
                border: 1px solid var(--sf-layout-header-menu-bar-link-hover-color);
                box-shadow: 0 0 8px rgba(0, 0, 0, .25);
                text-decoration: none;
            }
        }
    }
}

.layoutHeaderMenuBar__link__icon {
    margin-inline-end: 8px;
    color: var(--sf-layout-header-menu-bar-link-icon-color);

    .svgicon {
        opacity: var(--sf-layout-header-menu-bar-link-icon-opacity);
    }
}

.layoutHeaderMenuBar__link__arrow {
    transform: scale(.4);
    margin-inline-end: -.25em;
    color: var(--sf-layout-header-menu-bar-link-arrow-color);

    .svgicon {
        transition: transform $transition-duration;

        .layoutHeaderMenuBar__link.--active & {
            transform: scaleY(-1);
        }
    }
}

.--layoutHeaderMenuBar__carVehicleType {
    display: var(--sf-layout-header-menu-bar-car-vehicle-type-display) !important;;
}

.--layoutHeaderMenuBar__utilityVehicleType {
    display: var(--sf-layout-header-menu-bar-utility-vehicle-type-display) !important;;
}

.--layoutHeaderMenuBar__motorcycleVehicleType {
    display: var(--sf-layout-header-menu-bar-motorcycle-vehicle-type-display) !important;
}

.--layoutHeaderMenuBar__allCategories {
    display: var(--sf-layout-header-menu-bar-all-categories-display) !important;
}