@import "../constants";
@import "../mixins/svgiconMixins";
@import "../mixins/blockButtonMixins";

.vehicleModels {
    user-select: none;
}

.vehicleModels__section {
    position: relative;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--sf-line-color-light);
    }

    @media (min-width: $screen-600px) {
        padding-inline-start: 25%;
        &:not(:last-of-type) {
            margin-bottom: 8px;
            padding-bottom: 8px;
        }
    }
}

.vehicleModels__heading {
    color: var(--sf-text-color);
    margin: 0;
    @media (min-width: $screen-600px) {
        font-size: var(--sf-heading-4-font-size);
        position: absolute;
        left: 0;
        top: 4px;
        line-height: 1;
        &:dir(rtl) {
            left: initial;
            right: 0;
        }
        .svgicon {
            display: none;
        }
    }
    @media (max-width: $screen-600px-1) {
        font-size: 1rem;
        position: relative;
        //-webkit-tap-highlight-color: var(--sf-color-primary);
        cursor: pointer;
        line-height: var(--sf-btn-height);
        margin: 0 10px;
        .svgicon {
            display: block;
            line-height: 12px;
            position: absolute;
            right: 0;
            &:dir(rtl) {
                right: initial;
                left: 0;
            }
            top: unquote("calc(50% - 6px)");
            transition: transform 0.2s;
            z-index: 10;
            @include svgicon-size(12px);
            opacity: .5;

            .vehicleModels__section--open & {
                transform: rotateZ(-180deg);
            }
        }
    }
}

.vehicleModels__models {
    @media (min-width: $screen-900px) {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    @media (max-width: $screen-600px-1) {
        display: none;
        margin-bottom: 8px;
        .vehicleModels__section--open & {
            display: block;
        }
    }
}

.vehicleModels__link {
    direction: ltr;
    display: block;
    border-radius: var(--sf-border-radius);
    padding: 8px;
    margin: 1px;
    position: relative;
    font-weight: var(--sf-font-weight-semibold);
    @include blockButton(transparent);
    @include blockButton--hoverable();
}

.vehicleModels__modelName {
    margin-inline-end: 5px;
}

.vehicleModels__saleTime {
    line-height: $line-height * 1rem;
    font-size: $font-size-075rem;
    font-weight: var(--sf-font-weight-normal);
    white-space: nowrap;
    color: var(--sf-text-color);
    opacity: .75;
}