@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/scrollMixins";
@import "../mixins/buttonLinkMixins";

.pagePartVehicleBrandGrid {
    h2 {
        user-select: none;
        margin-bottom: 1rem;
    }
}

.pagePartVehicleBrandGrid__vehicleTypeSection {
    &.--car {
        display: var(--sf-vehicle-brand-grid-type-car-display);
    }

    &.--motorcycle {
        display: var(--sf-vehicle-brand-grid-type-motorcycle-display);
    }

    &.--utility {
        display: var(--sf-vehicle-brand-grid-type-utility-display);
    }
}

.pagePartVehicleBrandGrid__vehicleTypeSection:not(:last-child) {
    margin-bottom: 16px;
}

.pagePartVehicleBrandGrid__vehicleUnselect {
    @include btnLinkUnderlinedLightTextColor();
    @include btnExtraSmall();
}

//.pagePartVehicleBrandGrid__carHeader {
//    margin-bottom: 4px;
//    @media (min-width: $screen-600px) {
//        display: flex;
//        align-items: center;
//    }
//    @media (min-width: $screen-1200px) {
//        margin-bottom: 0;
//    }
//
//    h2 {
//        margin-inline-end: auto;
//        @media (min-width: $screen-600px) {
//            margin-bottom: 0;
//        }
//        @media (min-width: $screen-1200px) {
//            flex-basis: 100%*0.125*3;
//            margin-inline-end: 0;
//        }
//    }
//}

.pagePartVehicleBrandGrid__licensePlateSearch {
    display: block;
    @media (min-width: $screen-600px) {
        flex-basis: 25%;
    }
}

.pagePartVehicleBrandGrid__btnContainer {
    padding-top: var(--sf-grid-nav-gap-block);
    @media (min-width: $screen-520px) {
        padding: var(--sf-grid-nav-gap-block) (100% * 0.125 * 2) 16px;
    }
    @media (min-width: $screen-1200px) {
        padding: var(--sf-grid-nav-gap-block) (100% * 0.125 * 3) 0;
    }
}

.pagePartVehicleBrandGrid__btn {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .svgicon {
        display: block;
        margin-inline-end: .5em;
        line-height: 1;
        transform: translateY(-.15em);
    }
}

.pagePartVehicleBrandGrid__nav {
}

.pagePartVehicleBrandGrid__nav__viewport {
    padding: 1px;
    min-width: 800px;
    display: grid;
    grid-template-columns: repeat(var(--sf-page-part-vehicle-grid-column-count, 8), minmax(0, 1fr));
    gap: var(--sf-grid-nav-gap-block) var(--sf-grid-nav-gap);

    &.--6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    &.--8 {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    &.--10 {
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }
    &.--12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
}

.pagePartVehicleBrandGrid__nav__item {
    display: flex;
}

.pagePartVehicleBrandGrid__nav__item__link {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    aspect-ratio: var(--sf-vehicle-brand-grid-item-aspect-ratio);
    @include blockButton();
    @include blockButton--bg();
    @include blockButton--hoverable();

    &:not(&--withLogo) {
        justify-content: center;
    }
}

.pagePartVehicleBrandGrid__nav__item__name {
    text-align: center;
    font-size: $font-size-088rem;
    color: var(--sf-text-color);
    padding: 0 6px 6px;
    font-weight: var(--sf-font-weight-semibold);
    white-space: nowrap;
    overflow: hidden;
    opacity: .75;
    display: block;
    text-overflow: ellipsis;
    @media (max-width: $screen-900px-1) {
        font-size: $font-size-075rem;
    }
}

.pagePartVehicleBrandGrid__nav__item__logo {
    display: flex;
    height: 64px;
    width: 100%;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    picture {
        display: contents;
    }

    img {
        object-fit: contain;
        -webkit-user-drag: none;
        margin: auto;
        display: block;
        max-height: 50%;
        max-width: 50%;
        height: auto;
        image-rendering: auto;
        -ms-interpolation-mode: bicubic;
        opacity: 0;
        transition: opacity $transition-duration;

        &.loaded {
            opacity: 1;
        }
    }

    @media (max-width: $screen-900px-1) {
        height: 64px;
    }
}

.pagePartVehicleBrandGrid__nav__item__link.--allBrands {
    background-color: var(--sf-block-button-highlighted-bg-color);
    border-color: var(--sf-block-button-highlighted-border-color);
    color: var(--sf-block-button-highlighted-text-color);
    //color: var(--sf-text-color);
}

.pagePartVehicleBrandGrid__nav__item__allBrands {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    text-align: center;
    line-height: 1.35;
    font-weight: var(--sf-font-weight-bold);
    white-space: normal;
}