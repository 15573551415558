@use "sass:math";

@import "../constants";
@import "./colorMixins";


@mixin blockButton($borderColor: var(--sf-block-button-border-color), $bgColor: white) {
    border-radius: var(--sf-border-radius);
    border: 1px solid $borderColor;
    background-color: $bgColor;
    //cursor: pointer;
    box-shadow: var(--sf-block-button-box-shadow);
}

@mixin blockButton--bg() {
    border-radius: var(--sf-border-radius);
    background-color: var(--sf-block-button-bg-color);
    border: 1px solid var(--sf-block-button-bg-border-color);
    box-shadow: var(--sf-block-button-bg-box-shadow);
}

@mixin blockButton--hoverable($borderColor: var(--sf-block-button-hover-border-color)) {
    //cursor: pointer;
    //will-change: box-shadow, border-color;
    @media (hover) {
        &:hover {
            box-shadow: 0 0 0 1px $borderColor, var(--sf-block-shadow);
            border-color: $borderColor;
        }
    }
}

@mixin blockButton--bgHoverable($bgColor: var(--sf-block-button-hover-border-color), $color: var(--sf-block-button-hover-text-color)) {
    //cursor: pointer;
    //will-change: box-shadow, border-color, color;
    @media (hover) {
        &:hover {
            color: $color;
            box-shadow: 0 0 0 1px $bgColor, var(--sf-block-shadow);
            border-color: $bgColor;
            background-color: $bgColor;
        }
    }
}

@mixin blockButton--current($color: var(--sf-block-button-current-border-color), $backgroundColor: white) {
    box-shadow: 0 0 0 1px $color, var(--sf-block-shadow) !important;
    border-color: $color !important;
    background-color: $backgroundColor !important;
}

@mixin blockButton--bgCurrent($bgColor: var(--sf-block-button-current-border-color), $color: var(--sf-block-button-hover-text-color)) {
    box-shadow: 0 0 0 1px $bgColor, var(--sf-block-shadow) !important;
    border-color: $bgColor !important;
    background-color: $bgColor !important;
    color: $color;
}

@mixin blockButton--activeGreen() {
    @include blockButton--current($color-green);
    background-color: rgba($color-green, math.div(10%, 100.0%)) !important;
    color: var(--sf-text-color);
}


@mixin blockButton--transitions() {
    transition-property: box-shadow, background-color, color, border-color;
    transition-duration: $transition-duration;
    transition-timing-function: ease-in;
}