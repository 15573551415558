@import "../constants";
@import "../mixins/typeMixins";

.articleList {
    &.--withColumns {
        @media (min-width: $screen-1200px) {
            grid-template-columns: 1fr 1fr;
            display: grid;
            gap: 0 48px;
        }
    }
}

.articleList__article {
    border-bottom: 1px solid var(--sf-line-color-100);
    margin-bottom: 8px;
    padding-bottom: 8px;

    @media (min-width: $screen-520px) {
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    &.--withImage {
        display: grid;
        gap: 20px;
        @media (min-width: $screen-320px) {
            grid-template-columns: 35% 1fr;
        }
        @media (min-width: $screen-520px) {
            grid-template-columns: 27% 1fr;
        }
        @media (min-width: $screen-1200px) {
            grid-template-columns: 240px 1fr;
            &.--withColumns {
                grid-template-columns: 35% 1fr;
            }
        }
    }
}

.articleList__article__image {
    display: flex;
    border-radius: var(--sf-border-radius);
    aspect-ratio: 1/1;
    background-color: var(--sf-pane-bg-color);
    flex-shrink: 0;
    flex-basis: 0;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.articleList__article__image:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    left: var(--sf-article-list-article-image-before-left);
    top: var(--sf-article-list-article-image-before-top);
    background: var(--sf-article-list-article-image-before-bg);
    transform: var(--sf-article-list-article-image-before-transform);
    transform-origin: var(--sf-article-list-article-image-before-transform-origin);
}

.articleList__article__image:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    left: var(--sf-article-list-article-image-after-left);
    top: var(--sf-article-list-article-image-after-top);
    background: var(--sf-article-list-article-image-after-bg);
    transform: var(--sf-article-list-article-image-after-transform);
    transform-origin: var(--sf-article-list-article-image-after-transform-origin);
}

.articleList__article__image:before {
    position: absolute;
    content: '';
}

.articleList__article__image__picture {
    width: 100%; // because of Chrome
}

.articleList__article__image__img {
    display: block;
    border-radius: var(--sf-border-radius);
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    pointer-events: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
}

.articleList__article__header {
    align-items: baseline;
}

.articleList__article__header__time {
    opacity: .35;
}

.articleList__article__text {
    &.--withImage {
        margin-top: 16px;
    }

    @media (max-width: $screen-520px-1) {
        display: none;
    }
}

.articleList__article__perex {
    margin: 0;
    line-height: $line-height;
    opacity: .75;
    display: block;
    font-weight: var(--sf-font-weight-normal);
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--sf-text-color);
    text-decoration: none;
    //font-size: .9rem;
}