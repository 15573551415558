@import "../constants";

.layoutHeader {
    user-select: none;
    top: 0;
    position: relative;
    display: grid;
    z-index: var(--ksfdef-z-index-header);
    @media (max-width: $screen-760px-1) {
        margin-bottom: $layoutHeaderMainBar-default-height + 2 * $layoutHeaderMainBar-default-padding;
        html.--hideLayoutHeaderMenuBarAndSearch & {
            margin-bottom: 0;
        }
        //position: sticky;
        //.dropdown__content {
        //    height: unquote("calc(100vh - 47px)");
        //    overflow-y: scroll;
        //    -webkit-overflow-scrolling: touch;
        //    overscroll-behavior: contain;
        //}
    }
    background-color: var(--sf-layout-header-bg-color);
    background-image: var(--sf-layout-header-bg-image);
    background-position: var(--sf-layout-header-bg-position);
    background-size: var(--sf-layout-header-bg-size);
    background-repeat: var(--sf-layout-header-bg-repeat);
    box-shadow: var(--sf-layout-header-box-shadow);
    //@media (min-width: $screen-600px) {
    //transition: transform .5s $transition-timing-function;
    //&.--up:not(.--active) {
    //    transform: translateY(-$layoutHeaderMainBar-inside-height-md - 1px - 2 * $layoutHeader__mainBar-padding);
    //}
    //}
}

.layoutHeaderPage {
    background-color: var(--sf-layout-header-page-bg-color);
    background-image: var(--sf-layout-header-page-bg-image);
    box-shadow: var(--sf-layout-header-page-box-shadow);

    .page {
        padding-block: var(--sf-layout-header-page-padding-block);
        display: grid;
        gap: 8px;
    }

    .pagePart.--pageType-layoutHeader {
        margin: 0;
    }
}