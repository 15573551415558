@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/imageMixins";


.vehicleSchemaBriefs {
    display: grid;
    grid-template-columns: repeat(var(--layout-main-column-count-xlg), 1fr);
    gap: 16px;
}

.vehicleSchemaBriefs__brief {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @include blockButton(transparent);
    @include blockButton--hoverable();
    @include imageOverlay();
}

.vehicleSchemaBriefs__brief__imgWrap {
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 80%;
        height: 80%;
        object-fit: cover;
        display: block;
    }
}

.vehicleSchemaBriefs__name {
    align-self: stretch;
    padding: 16px;
    font-size: var(--sf-heading-4-font-size);
    font-weight: var(--sf-font-weight-bold) !important;
    line-height: $line-height-135;
    flex-grow: 1;
    color: var(--sf-link-color);

    @media (hover) {
        .vehicleSchemaBriefs__brief:hover & {
            color: var(--sf-link-hover-color);
        }
    }
}